var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"table"}},[_c('user-arrears-popover',{attrs:{"target":_vm.target,"container":"table"},on:{"openModal":_vm.openModal}}),_c('sw-table',{attrs:{"table-id":"c520b6ebf05f41c890f87ad53208043e","show-columns":"","fields":_vm.userFields,"pagination":_vm.pagination,"sorter":_vm.sorter,"show-search":"","show-sorter":""},on:{"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.getFunnelStructure()},"change-search-query":function($event){_vm.search = $event},"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.getFunnelStructure,"set-fields":function($event){_vm.userFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.tableData,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"head(select)",fn:function(){return [_c('b-form-checkbox',{on:{"change":_vm.toggleChecked},model:{value:(_vm.checkedAll),callback:function ($$v) {_vm.checkedAll=$$v},expression:"checkedAll"}})]},proxy:true},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"value":item},model:{value:(_vm.$store.state.funnelMain.selectedUsers),callback:function ($$v) {_vm.$set(_vm.$store.state.funnelMain, "selectedUsers", $$v)},expression:"$store.state.funnelMain.selectedUsers"}})]}},{key:"cell(arrears)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.status)?_c('div',[(item.status !== 'OPEN' && item.status === 'PAUSED')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn-icon rounded-circle mr-25",staticStyle:{"padding":"5px"},attrs:{"variant":'warning',"title":_vm.$t('Paused')}},[_c('feather-icon',{attrs:{"size":"17","icon":'PauseIcon'}})],1):(item.status !== 'OPEN' && item.status !== 'PAUSED')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn-icon rounded-circle mr-25",staticStyle:{"padding":"5px"},attrs:{"variant":item.status === 'CLOSED_POSITIVE' ? 'success' : 'danger',"title":item.status === 'CLOSED_POSITIVE' ? _vm.$t('funnel.status.COMPLETED') : _vm.$t('funnel.status.REJECTED')}},[_c('feather-icon',{attrs:{"size":"17","icon":item.status === 'CLOSED_POSITIVE' ? 'CheckIcon' : 'XIcon'}})],1):_vm._e()],1):_vm._e(),_c('user-arrears',{attrs:{"user":item},on:{"changeTarget":_vm.changeTarget}})],1)]}},{key:"cell(contactThread)",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{class:{ 'deleted-text': item.contact.deletedAt },attrs:{"to":("/contact/" + (item.contact.id) + "/thread/" + (item.id) + "/payments")}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,20))+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(contact)",fn:function(ref){
var value = ref.value;
return [(value)?_c('avatar',{attrs:{"user":value}}):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(agreeMarketing)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"variant":"primary","checked":item.contact.agreeMarketing,"disabled":""}})]}},{key:"cell(assignedUsers)",fn:function(ref){
var value = ref.value;
return [_c('avatar-group',{attrs:{"items":value}})]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.contact.status && item.contact.status.name)?_c('b-badge',{staticClass:"cursor-pointer",style:({ backgroundColor: item.contact.status.color + ' !important' }),attrs:{"id":("status_row_" + (item.id) + "_" + index)}},[_vm._v(" "+_vm._s(item.contact.status.name)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}}),(item.contact.status && item.contact.status.name)?_c('b-popover',{attrs:{"target":("status_row_" + (item.id) + "_" + index),"placement":"left","triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('funnel.ChangeStatus'))+" ")])]},proxy:true}],null,true)},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.contactStatuses),function(status){return _c('b-list-group-item',{key:("status__" + (status.id)),staticClass:"cursor-pointer",attrs:{"disabled":status.id === item.contact.status.id},on:{"click":function($event){return _vm.onChangeContactStatus(status.id, item.id, item.contact)}}},[(status && status.name)?_c('span',[_vm._v(_vm._s(status.name))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)}),1)],1):_vm._e()]}},{key:"cell(contactTypes)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(Array.isArray(item.contact.contactTypes) && item.contact.contactTypes.length)?_c('div',_vm._l((item.contact.contactTypes),function(tag){return _c('b-badge',{key:("tag_" + (tag.id) + "_" + index),staticClass:"mr-25 mb-25",attrs:{"variant":"light-secondary"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"color-dot mr-25",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: tag.color || '#fff' })}),_vm._v(" "+_vm._s(tag.name)+" ")])])}),1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(checklistStatus)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"id":("status_checklistStatus_row_" + (item.id)),"variant":"light-primary"}},[(item.contactThreadChecklistDetails.length)?[_vm._v(" "+_vm._s(((_vm.checklistProgress(item.contactThreadChecklistDetails).done) + " / " + (_vm.checklistProgress(item.contactThreadChecklistDetails).all)))+" ")]:_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],2)]}},{key:"cell(statusesProgress)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"id":("status_checklistStatus_row_" + (item.id)),"variant":"light-primary"}},[(item.contactThreadChecklistDetails.length)?[_vm._v(" "+_vm._s(_vm.checklistProgress(item.contactThreadChecklistDetails).percent)+"% ")]:_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],2)]}},{key:"cell(organization)",fn:function(ref){
var item = ref.item;
return [(item.contact.organization)?_c('span',[(item.contact.organization.name)?_c('span',[(_vm.checkRequiredPermissions([_vm.$roles.ORGANIZATION_VIEW_ALL]) && item.contact.organization.id)?_c('b-link',{attrs:{"to":("/organizations/" + (item.contact.organization.id)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.contact.organization.name)+" ")]):_c('span',[_vm._v(_vm._s(item.contact.organization.name))])],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(vatId)",fn:function(ref){
var item = ref.item;
return [(item.contact.organization)?_c('span',[(item.contact.organization.vatId)?_c('span',[_vm._v(_vm._s(item.contact.organization.vatId))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(address)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item.contact))+" ")])]}},{key:"cell(addressCorrespondence)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item.contact, 'correspondence'))+" ")])]}},{key:"cell(contactThreadFunnelTypePoint)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadFunnelTypePoint && item.contactThreadFunnelTypePoint.name)?_c('b-badge',{staticClass:"cursor-pointer",attrs:{"id":("status_" + (item.id)),"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.contactThreadFunnelTypePoint.name)+" "),(item.contactThreadFunnelTypePoint.description)?_c('sw-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-primary ml-25",attrs:{"icon":"AlertCircleIcon","title":item.contactThreadFunnelTypePoint.description}}):_vm._e()],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])}),_c('mass-update-modal',{key:'mass-update-modal-table' +_vm.massUpdateContacts,attrs:{"contacts":_vm.massUpdateContacts,"show-modal":_vm.showMassUpdateModal},on:{"close-modal":_vm.closeMassUpdateModal}}),(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_THREAD_ADD]))?_c('import-contact-threads-modal',{attrs:{"show":_vm.showThreadImport},on:{"close-modal":function () { _vm.$emit('closeModal'); _vm.getFunnelStructure() },"reload-content":_vm.closeMassUpdateModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }